import React from 'react';
import Link from 'next/link';
import { useTheme, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import MuiLink from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useI18n } from 'i18n';
import LocaleSwitcher from 'src/components/LocaleSwitcher';
import ThemeSwitcher from 'src/components/ThemeSwitcher';
import Copyright from 'src/components/Copyright';

const Title = styled(Typography)({
  fontWeight: 'bold',
});

const LinkText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

export default function Footer(): React.ReactElement {
  const { t } = useI18n();
  const theme = useTheme();

  return (
    <footer style={{ borderTop: 'solid 1px #999', flexGrow: 1, padding: theme.spacing(3), marginBottom: theme.spacing(3) }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Title variant="h6">
              {t('learn')}
            </Title>
            <Link href={'/docs'} passHref legacyBehavior>
              <MuiLink>
                <LinkText variant="subtitle1">
                  {t('documents')}
                </LinkText>
              </MuiLink>
            </Link>
            <Link href={'/products'} passHref legacyBehavior>
              <MuiLink>
                <LinkText variant="subtitle1">
                  {t('products')}
                </LinkText>
              </MuiLink>
            </Link>
            <MuiLink href="https://github.com/baues" target="_blank" rel="noopener noreferrer">
              <LinkText variant="subtitle1">
                Github <OpenInNewIcon fontSize="inherit" style={{ marginBottom: -3 }} />
              </LinkText>
            </MuiLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Title variant="h6">
              {t('stay-connected')}
            </Title>
            <MuiLink href="https://blog.baues.io" target="_blank" rel="noopener noreferrer">
              <LinkText variant="subtitle1">
                {t('blog')} <OpenInNewIcon fontSize="inherit" style={{ marginBottom: -3 }} />
              </LinkText>
            </MuiLink>
            <MuiLink href="https://twitter.com/BAUES_IO" target="_blank" rel="noopener noreferrer">
              <LinkText variant="subtitle1">
                Twitter <OpenInNewIcon fontSize="inherit" style={{ marginBottom: -3 }} />
              </LinkText>
            </MuiLink>
            <MuiLink href="https://www.facebook.com/baues.io" target="_blank" rel="noopener noreferrer">
              <LinkText variant="subtitle1">
                Facebook <OpenInNewIcon fontSize="inherit" style={{ marginBottom: -3 }} />
              </LinkText>
            </MuiLink>
            <MuiLink href="https://slack.baues.io" target="_blank" rel="noopener noreferrer">
              <LinkText variant="subtitle1">
                {t('slack-community')} <OpenInNewIcon fontSize="inherit" style={{ marginBottom: -3 }} />
              </LinkText>
            </MuiLink>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Title variant="h6">
              {t('support')}
            </Title>
            <MuiLink href="https://baues.io/contact" target="_blank" rel="noopener noreferrer">
              <LinkText variant="subtitle1">
                {t('contact-support')} <OpenInNewIcon fontSize="inherit" style={{ marginBottom: -3 }} />
              </LinkText>
            </MuiLink>
            <Link href={'/use-cases'} passHref legacyBehavior>
              <MuiLink>
                <LinkText variant="subtitle1">
                  {t('use-cases')}
                </LinkText>
              </MuiLink>
            </Link>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <Stack direction="row" spacing={2}>
                <LocaleSwitcher />
                <ThemeSwitcher />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Link href="/terms" passHref legacyBehavior>
                  <MuiLink>
                    <Typography variant="subtitle1" sx={{ m: 1 }}>
                      {t('terms')}
                    </Typography>
                  </MuiLink>
                </Link>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Copyright />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

/*
              <div className={classes.row}>
                <Link href="/terms" className={classes.terms}>
                  <Typography variant="subtitle1">{t('terms')}</Typography>
                </Link>|
                <MuiLink href="https://baues.io/privacy" className={classes.terms}>
                  <Typography variant="subtitle1">{t('privacy')}</Typography>
                </MuiLink>
              </div>
*/
