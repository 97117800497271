import React from 'react';
import Image from 'next/image';
import LogoIconWhite from 'public/images/logo_i_white.png';
import LogoIcon from 'public/images/logo_i.png';
import LogoWhite from 'public/images/logo_white.png';
import Logo from 'public/images/logo.png';

interface Props {
  scale?: number;
  white?: boolean;
  icon?: boolean;
}

export default function LogoImage({ scale = 1, white = false, icon = false }: Props): React.ReactElement {
  if (icon) {
    return <Image src={white ? LogoIconWhite : LogoIcon} alt="BAUES" width={37 * scale} height={29 * scale} />;
  }

  return <Image src={white ? LogoWhite : Logo} alt="BAUES" width={181 * scale} height={29 * scale} placeholder="blur" />;
}
