import { LocaleKey } from 'i18n';

export interface Navigation {
  id: LocaleKey;
  href: string;
}

export type Navigations = Array<Navigation>;

const navigations: Navigations = [
  {
    id: 'products',
    href: '/products',
  },
  {
    id: 'use-cases',
    href: '/use-cases',
  },
  {
    id: 'documents',
    href: '/docs',
  },
  {
    id: 'support',
    href: '/support',
  },
];

export default navigations;
