import React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoImage from 'src/components/BauesIncLogoImage';

const date = new Date();

export default function Copyright(): React.ReactElement {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <a
      href="https://baues.co.jp"
      style={{ textDecoration: 'none', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column', padding: theme.spacing(1) }}
    >
      <div style={{ marginRight: theme.spacing(1) }}>
        <LogoImage white={theme.palette.mode === 'dark'} scale={1.2} />
      </div>
      <Typography variant="body2" color="textSecondary" align={matches ? 'right' : 'left'} style={{ marginTop: theme.spacing(1) }}>
        Copyright &copy; {date.getFullYear()} BAUES Inc.
      </Typography>
    </a>
  );
}
