import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import { useI18n } from 'i18n';
import { getFirstDocId } from 'src/utils/docs';
import navs, { Navigation } from 'src/navigations';
import { RefA } from 'src/components/A';

interface NavButtonProps {
  nav: Navigation;
}

function NavButton({ nav }: NavButtonProps): React.ReactElement {
  const { t } = useI18n();
  const router = useRouter();
  const theme = useTheme();
  const active = router.pathname.includes(`${nav.href}`);

  const navLink = (): string => {
    if (nav.href === '/docs') {
      return `/${router.locale}/docs/${getFirstDocId()}`;
    } else {
      return `/${router.locale}${nav.href}`;
    }
  };

  return (
    <Link href={navLink()} passHref legacyBehavior>
      <RefA>
        <Button
          color={active ? 'secondary' : 'inherit'}
          sx={{
            fontWeight: active ? 'bold' : 'normal',
            p: 0,
            borderRadius: 0,
          }}
        >
          <Toolbar
            sx={{
              borderBottom: active ? `solid 3px ${theme.palette.secondary.main}` : 'solid 3px transparent',
              '&:hover': {
                borderBottom: `solid 3px ${theme.palette.grey[500]}`,
              },
            }}
          >
            {t(nav.id)}
          </Toolbar>
        </Button>
      </RefA>
    </Link>
  );
}

export default function Nav(): React.ReactElement {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
    >
      {navs.map((nav) => {
        return <NavButton key={nav.id} nav={nav} />;
      })}
    </Box>
  );
}
