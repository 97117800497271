import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useI18n } from 'i18n';
import AppDrawerItems from './AppDrawerItems';

export const drawerWidth = 240;

interface Props {
  mobileOpen: boolean;
  handleDrawerClose: () => void;
}

function AppDrawer(props: Props): React.ReactElement {
  const { mobileOpen, handleDrawerClose } = props;
  const theme = useTheme();
  const { t } = useI18n();

  return (
    <Box
      sx={{
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      }}
      aria-label={t('navigation')}
    >
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            width: drawerWidth,
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <AppDrawerItems handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
}

export default AppDrawer;
