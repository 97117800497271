import React from 'react';
import Image from 'next/image';
import bauesInc from 'public/images/baues_inc.png';
import bauesIncWhite from 'public/images/baues_inc_white.png';

interface Props {
  scale?: number;
  white?: boolean;
}

export default function LogoImage({ scale = 1, white = false }: Props): React.ReactElement {
  const src = white ? bauesIncWhite : bauesInc;

  return (
    <Image src={src} alt='BAUES' width={110.5 * scale} height={29.2 * scale} placeholder="blur" />
  );
}
