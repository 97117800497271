import React, { useState } from 'react';
import Link from 'next/link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useI18n } from 'i18n';
import { DocId, DocSection } from 'src/docs/types';
import { RefA } from 'src/components/A';

export const drawerWidth = 340;

interface Props {
  section: DocSection;
  id: DocId;
  hasChildren: boolean;
  handleClose: () => void;
}

export default function Section({ section, id, hasChildren, handleClose }: Props): React.ReactElement {
  const { t } = useI18n();
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  if (hasChildren) {
    const parentActive = section.children.includes(id);
    return (
      <>
        <ListItem button onClick={(): void => toggleOpen()}>
          <ListItemText primaryTypographyProps={{ sx: { fontWeight: 'bold' }, color: parentActive ? 'secondary' : 'inherit' }} primary={t(section.parent)} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {hasChildren &&
              section.children.map((child, childIndex) => {
                const active = id === child;
                return (
                  <Link key={childIndex} href={`/docs/${child}`} passHref legacyBehavior>
                    <RefA>
                      <ListItem button sx={{ pl: 4 }} onClick={handleClose}>
                        <ListItemText primary={t(child)} primaryTypographyProps={{ color: active ? 'secondary' : 'inherit', style: { fontWeight: active ? 'bold' : 'normal' } }} />
                      </ListItem>
                    </RefA>
                  </Link>
                );
              })}
          </List>
        </Collapse>
      </>
    );
  }

  const active = id === section.parent;

  return (
    <Link href={`/docs/${section.parent}`} passHref legacyBehavior>
      <RefA>
        <ListItem button alignItems="flex-start" onClick={handleClose}>
          <ListItemText primary={t(section.parent)} primaryTypographyProps={{ sx: { fontWeight: 'bold' }, color: active ? 'secondary' : 'inherit' }} />
        </ListItem>
      </RefA>
    </Link>
  );
}
