import React from 'react';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import { useI18n } from 'i18n';
import A from 'src/components/A';
import { useMobile } from 'src/utils/useWindowSize';
import UserMenu from './UserMenu';

const User: React.VFC = () => {
  const mobile = useMobile();
  const theme = useTheme();
  const dark = theme.palette.mode === 'dark';
  const { user } = useUser();
  const { asPath, locale } = useRouter();
  const { t } = useI18n();

  if (user) {
    return <UserMenu />;
  }

  return (
    <>
      {mobile
        ? (
        <A href={`/api/auth/login?locale=${locale}&returnTo=${asPath}`}>
          <IconButton aria-label={t('sign-in')} color={dark ? 'default' : 'primary'} size="large">
            <LockIcon />
          </IconButton>
        </A>
          )
        : (
        <>
          <A href={`/api/auth/login?locale=${locale}&returnTo=${asPath}`} style={{ marginRight: 16 }}>
            <Button variant="text" aria-label={t('sign-in')} color="inherit">
              {t('sign-in')}
            </Button>
          </A>
          <A href={`/api/auth/login?prompt=login&screen_hint=signup&locale=${locale}`}>
            <Button variant="outlined" aria-label={t('sign-up')} color="inherit">
              {t('sign-up')}
            </Button>
          </A>
        </>
          )}
    </>
  );
};

export default User;
