import React, { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useThemeContext } from '../theme/ThemeContext';
import { lightTheme, darkTheme } from '../theme/theme';
import { useI18n } from 'i18n';

export default function ThemeSwitcher(): React.ReactElement {
  const theme = useTheme();
  const themeType = theme.palette.mode;
  const { setTheme } = useThemeContext();
  const { t } = useI18n();

  const handleClick = useCallback(() => {
    setTheme(themeType === 'light' ? darkTheme : lightTheme);
  }, [setTheme, themeType]);

  return (
    <Tooltip title={t('toggle-theme')}>
      <IconButton color="secondary" aria-label={t('toggle-theme')} onClick={handleClick} size="large" sx={{ m: 1 }}>
        {themeType === 'light' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  );
}
