import React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import LocaleSwitcher from '../LocaleSwitcher';
import ThemeSwitcher from '../ThemeSwitcher';
import { useI18n } from 'i18n';
import { docList } from '../../docs/config';
import { DocId } from '../../docs/types';
import DocsNavigationSection from './DocsNavigationSection';

export const drawerWidth = 240;
export const drawerWidthWide = 340;

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  id: DocId;
}

export default function DocsNavigation({ id }: Props): React.ReactElement {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(false);
  const { t } = useI18n();

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      {matches ? (
        <Drawer
          sx={{
            width: drawerWidth,
            [theme.breakpoints.up('lg')]: {
              width: drawerWidthWide,
            },
            flexShrink: 0,
          }}
          variant="permanent"
          PaperProps={{
            sx: {
              width: drawerWidth,
              [theme.breakpoints.up('lg')]: {
                width: drawerWidthWide,
              },
            },
          }}
          anchor="left"
        >
          <Toolbar />
          <div
            style={{
              overflow: 'auto',
            }}
          >
            <List component="nav" sx={{ width: '100%', maxWidth: drawerWidthWide, backgroundColor: theme.palette.background.paper }}>
              {docList.map((section, index) => {
                const hasChildren = Array.isArray(section.children) && section.children.length > 0;
                return <DocsNavigationSection key={index} section={section} id={id} hasChildren={hasChildren} handleClose={handleClose} />;
              })}
            </List>
          </div>
          <div style={{ flexGrow: 1 }} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <LocaleSwitcher />
            <ThemeSwitcher />
          </div>
        </Drawer>
      ) : (
        <div>
          <Fab
            sx={{ position: 'fixed', bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2), zIndex: 1 }}
            color="primary"
            aria-label={t('documents-menu')}
            onClick={handleClickOpen}
          >
            <MenuOpenIcon />
          </Fab>
          <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 2, flex: 1 }}>
                  {t('documents-menu')}
                </Typography>
              </Toolbar>
            </AppBar>
            <div style={{ overflow: 'auto' }}>
              <List>
                {docList.map((section, index) => {
                  const hasChildren = Array.isArray(section.children) && section.children.length > 0;
                  return <DocsNavigationSection key={index} section={section} id={id} hasChildren={hasChildren} handleClose={handleClose} />;
                })}
              </List>
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <LocaleSwitcher />
              <ThemeSwitcher />
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
}
