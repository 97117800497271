import React from 'react';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { LinkProps } from 'next/link';
import Link from './FunctionLink';

type ButtonLinkProps = Omit<ButtonProps, 'href'> &
  Omit<LinkProps, 'children' | 'passHref'> & {
    className?: string;
    target?: string;
  };

const ButtonLink: React.FC<ButtonLinkProps> = ({ href, as, target, prefetch, shallow, scroll, replace, className, children, ...otherProps }) => (
  <Link href={href} as={as} target={target} prefetch={prefetch} shallow={shallow} scroll={scroll} replace={replace} passHref>
    <Button className={className} {...otherProps}>
      {children}
    </Button>
  </Link>
);

export default ButtonLink;
