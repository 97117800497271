import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { DocId } from 'src/docs/types';

import DocsNavigation, { drawerWidth, drawerWidthWide } from './Docs/DocsNavigation';
import Header from './Header';
import Footer from './Footer';

type Props =
  | {
      children: React.ReactNode;
      id?: DocId;
      doc?: false;
    }
  | {
      children: React.ReactNode;
      id: DocId;
      doc: true;
    };

export default function Layout({ children, id, doc = false }: Props): React.ReactElement {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
      }}
    >
      <Header />
      {doc && id ? <DocsNavigation id={id} /> : null}
      <Box
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: 0,
          [theme.breakpoints.up('md')]: {
            marginLeft: doc ? `${drawerWidth}px` : 0,
          },
          [theme.breakpoints.up('lg')]: {
            marginLeft: doc ? `${drawerWidthWide}px` : 0,
          },
        }}
      >
        <Container maxWidth="lg" sx={{ padding: 0 }}>
          <Toolbar />
          {children}
        </Container>
      </Box>
      {!doc && <Footer />}
    </Box>
  );
}
