import React from 'react';

type Props = React.HTMLProps<HTMLAnchorElement>;

const defaultStyle = { textDecoration: 'none', color: 'inherit' };

const RefA = React.forwardRef<HTMLAnchorElement, Props>(({ style, ...others }, ref) => {
  return <a ref={ref} style={{ ...defaultStyle, ...style }} {...others} />;
});

const A: React.VFC<Props> = (({ style, ...others }) => {
  return <a style={{...defaultStyle, ...style }} {...others} />;
});

export { RefA };
export default A;
