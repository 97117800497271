import React from 'react';
import Link from 'next/link';
import { MenuItem, MenuItemProps } from '@mui/material';

type LinkMenuItemProps = Omit<MenuItemProps<'a', { href: string; locale?: string | false }>, 'component' | 'button'> & {
  anchor?: boolean;
};

const LinkMenuItem = React.forwardRef<HTMLAnchorElement, LinkMenuItemProps>(function LinkMenuItem(props, forwardedRef) {
  const { href, locale, anchor, children, ...other } = props;
  if (anchor) {
    return (
      <MenuItem component="a" href={href} {...other}>
        {children}
      </MenuItem>
    );
  }

  return (
    <Link href={href} locale={locale} passHref style={{ textDecoration: 'none', color: 'inherit' }}>
      <MenuItem component="a" ref={forwardedRef} {...other}>
        {children}
      </MenuItem>
    </Link>
  );
});

export default LinkMenuItem;
