import { DocList } from './types';

export const docList: DocList = [
  {
    parent: 'introduction',
    children: [],
  },
  {
    parent: 'get-started',
    children: ['prepare-model', 'basic-flow'],
  },
  {
    parent: 'tutorial',
    children: ['start-project', 'setup-models', 'input-levels', 'edit-plans', 'edit-model', 'input-conditions', 'run-simulation', 'results','upload-grasshopper'],
  },
  {
    parent: 'reference',
    children: ['default-parameter', 'energyplus', 'epw', 'guidelines', 'model-requirements', 'contributors'],
  },
];
