import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useI18n } from 'i18n';
import { LocaleKey } from 'i18n';

const label = 'Language';

interface Props {
  color?: string;
}

const LocaleSwitcher: React.FC<Props> = ({ color }) => {
  const { locale, locales, push, asPath } = useRouter();
  const { t } = useI18n();

  const handleLocaleChange = useCallback(() => {
    if (!locales || locales.length < 2 || !locale) return;

    const newLocale = locales.filter((item) => item !== locale)[0];
    push(asPath, asPath, { locale: newLocale });
  }, [asPath, locale, locales, push]);

  const CustomizedSelect = useMemo(() => {
    return styled(Select)(`
      color: ${color || 'inherit'};
    
      & .MuiFormLabel-filled {
        color: ${color || 'inherit'};
      }
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${color || 'inherit'};
      }
      & .MuiSelect-icon {
        color: ${color || 'inherit'};
      }
    `);
  }, [color]);

  if (!locales || locales.length < 2 || !locale) return <></>;

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="language-label" style={{ color }}>
        {label}
      </InputLabel>
      <CustomizedSelect labelId="language-label" id="language" value={locale} onChange={handleLocaleChange} label={label}>
        {locales.map((locale) => (
          <MenuItem key={locale} value={locale}>
            {t(locale as LocaleKey)}
          </MenuItem>
        ))}
      </CustomizedSelect>
    </FormControl>
  );
};

export default LocaleSwitcher;
