import { docList } from '../docs/config';

export function getFirstDocId(): string {
  const firstDocSection = docList[0];
  let docId: string;

  if (Array.isArray(firstDocSection.children) && firstDocSection.children.length > 0) {
    docId = firstDocSection.children[0];
  } else { // parent is md file
    docId = firstDocSection.parent;
  }

  return docId;
}
