import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { useI18n } from 'i18n';
import navs from 'src/navigations';
import { getFirstDocId } from 'src/utils/docs';
import { useMobile } from 'src/utils/useWindowSize';

interface AppDrawerItemsProps {
  handleDrawerClose(): void;
}

export default function AppDrawerItems({ handleDrawerClose }: AppDrawerItemsProps): React.ReactElement {
  const { locale } = useRouter();
  const { t } = useI18n();
  const mobile = useMobile();

  return (
    <List>
      {navs.map((nav, i) => {
        const navLink = (): string => {
          if (nav.href === '/docs') {
            return `/docs/${getFirstDocId()}`;
          } else {
            return nav.href;
          }
        };

        return (
          <React.Fragment key={i}>
            <Link key={nav.id} href={navLink()} passHref>
              <ListItem button onClick={handleDrawerClose}>
                <ListItemText primary={t(nav.id)} />
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
        );
      })}
      {mobile && (
        <>
          <Link href={`/${locale}/app`} passHref>
            <ListItem button onClick={handleDrawerClose}>
              <ListItemText primary={t('go-to-app')} />
            </ListItem>
          </Link>
          <Divider />
        </>
      )}
    </List>
  );
}
