import React from 'react';
import Link from 'next/link';

type AnchorProps = React.HTMLProps<HTMLAnchorElement>;

const Child = React.forwardRef<HTMLAnchorElement, AnchorProps>(({ onClick, target, href, children }, ref) => {
  return (
    <a style={{ color: 'inherit', textDecoration: 'none' }} href={href} onClick={onClick} ref={ref} target={target} rel={target === '_blank' ? 'noopener noreferrer' : undefined}>
      {children}
    </a>
  );
});

type Props = React.ComponentProps<typeof Link> & {
  target?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export default function FunctionLink({ children, href, onClick, target, ...others }: Props) {
  return (
    <Link href={href} passHref {...others} legacyBehavior>
      <Child onClick={onClick} target={target}>
        {children}
      </Child>
    </Link>
  );
}
